<template>
    <b-tab :title="title" :disabled="!navigable" lazy>
        <b-row>
            <b-col cols="md-5">
                <b-row>
                    <b-col class="sticky taped m-3">
                        <p>
                            Domestic travelers <strong class="red">MUST</strong> use their
                            <strong class="italic">FULL NAME</strong> as it appears on their
                            non-expired, government issued photo ID (e.g. on their Driver's
                            License or Passport.)
                        </p>
                        <p>
                            International travelers <strong class="red">MUST</strong> use their
                            <strong class="italic">FULL NAME</strong> as it appears on their
                            Passport.
                        </p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="md-4">
                        <b-form-group label="First Name:" :state="!hasError('firstName')" invalid-feedback="First Name is Required.">
                            <template v-if="isDisabled">{{firstName}}</template>
                            <b-input-group size="sm" v-else>
                                <b-input v-model="firstName" placeholder="First Name" :state="!hasError('firstName')" :disabled="isDisabled"/>
                                <b-input-group-addon>
                                    <b-button :variant="hasError('firstName') ? 'danger' : 'secondary'" @click="firstName = null">
                                        <font-awesome-icon icon="times"/>
                                    </b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="md-4">
                        <b-form-group label="Middle Name:">
                            <template v-if="isDisabled">{{middleName}}</template>
                            <b-input-group size="sm" v-else>
                                <b-input v-model="middleName" placeholder="Middle Name" :disabled="isDisabled"/>
                                <b-input-group-addon>
                                    <b-button @click="middleName = null">
                                        <font-awesome-icon icon="times"/>
                                    </b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="md-4">
                        <b-form-group label="Last Name:" :state="!hasError('lastName')" invalid-feedback="Last Name is Required.">
                            <template v-if="isDisabled">{{lastName}}</template>
                            <b-input-group size="sm" v-else>
                                <b-input v-model="lastName" placeholder="Last Name" :state="!hasError('lastName')" :disabled="isDisabled"/>
                                <b-input-group-addon>
                                    <b-button :variant="hasError('lastName') ? 'danger' : 'secondary'" @click="lastName = null">
                                        <font-awesome-icon icon="times"/>
                                    </b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="md-4">
                        <b-form-group label="Suffix:">
                            <template v-if="isDisabled">{{suffix}}</template>
                            <b-input-group size="sm" v-else>
                                <b-input v-model="suffix" placeholder="Suffix" :disabled="isDisabled"/>
                                <b-input-group-addon>
                                    <b-button @click="suffix = null"><font-awesome-icon icon="times"/></b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col cols="md-4">
                        <b-form-group label="Date of Birth:" :state="!hasError('dateOfBirth')" :invalid-feedback="dateOfBirthErrorFeedback">
                            <template v-if="isDisabled">{{dateOfBirth | formatDate}}</template>
                            <picker-of-dates v-else
                                             v-model="dateOfBirth"
                                             format="M/d/yyyy"
                                             placeholder="Date of Birth"
                                             clearable
                                             hide-icon
                                             :state="!hasError('dateOfBirth')"
                                             :disabled="isDisabled">
                                </picker-of-dates>
                        </b-form-group>
                    </b-col>
                    <b-col cols="md-4">
                        <b-form-group label="Gender:" :state="!hasError('gender')" invalid-feedback="Gender is Required.">
                            <template v-if="isDisabled">{{gender | maleOrFemale}}</template>
                            <b-form-radio-group v-model="gender"
                                                buttons size="sm"
                                                :button-variant="hasError('gender') ? 'outline-danger' : 'outline-success'"
                                                name="gender"
                                                :options="genderOptions"
                                                :state="!hasError('gender')"
                                                :disabled="isDisabled"
                                                v-else/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Full Name: " :state="!hasError('confirm')">
                            <template #invalid-feedback>
                                <b-checkbox v-model="confirm" :value="true" :state="!hasError('confirm')" :disabled="isDisabled || hasError('firstName') || hasError('lastName')">
                                    You must confirm the above <strong>Full Name</strong> is correct.
                                </b-checkbox>
                            </template>
                            <template #valid-feedback>
                                <b-checkbox v-model="confirm" :value="true" :state="!hasError('confirm')" :disabled="isDisabled || hasError('firstName') || hasError('lastName')">
                                    The above <strong>Full Name</strong> has been confirmed.
                                </b-checkbox>
                            </template>
                            <template v-if="isDisabled">{{fullName}}</template>
                            <b-input size="sm" v-model="fullName" disabled v-else/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if="isFlightAccepted('to') || isFlightAccepted('from')">
                    <b-col>
                        <b-table-lite head-variant="dark" :table-variant="tableSummaryTableVariant"
                                      stacked="md" small striped borderless outlined
                                      :fields="selectedFlightFields" :items="selectedFlightData">
                            <template #cell(direction)="row">{{ row.item | flightDirection }}</template>
                            <template #cell(date)="row">{{row.item.dateOfFlight | formatDate}}</template>
                            <template #cell(departs)="row">
                                {{row.item.departCity}},
                                {{getState(row.item.departStateId).stateAbbreviation}}
                                ({{getAirport(row.item.departAirportId).iata}})
                            </template>
                            <template #cell(arrives)="row">
                                {{row.item.arriveCity}},
                                {{getState(row.item.arriveStateId).stateAbbreviation}}
                                ({{getAirport(row.item.arriveAirportId).iata}})
                            </template>
                            <template #custom-foot>
                                <b-td variant="dark" colspan="4" class="clearfix">
                                    <b-button class="float-right" v-if="isEditable"
                                              size="sm"
                                              variant="info"
                                              :disabled="!isEditable"
                                              @click="reset">Reset</b-button>
                                </b-td>
                            </template>
                            <template #table-caption>
                                <strong :class="'text-' + tableSummaryTableVariant" v-if="!isValidSelection">{{tableSummaryErrorCaption}}</strong>
                            </template>
                        </b-table-lite>
                    </b-col>
                </b-row>
                <b-row v-if="0 < errors.length">
                    <b-col>
                        <b-card border-variant="danger" no-body>
                            <b-card-header size="sm" header-bg-variant="danger" header-text-variant="white"><strong>Errors</strong></b-card-header>
                            <b-card-body>
                                <ol class="text-danger">
                                    <li v-for="error in errors">{{error}}</li>
                                </ol>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="md-7">
                <b-row>
                    <b-col cols="7" class="sticky taped m-3">
                        <template v-if="disabled">The highlighted days below are days {{firstName}} {{lastName}} will be flying.</template>
                        <template v-else>Click on a date to request departing and returning flights.</template>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <full-calendar ref="airfareCalendar"
                                       :plugins="calendarPlugins"
                                       :events="events"
                                       :default-date="defaultDate"
                                       @dateClick="onClick"
                                       @datesRender="calendarRendered"
                                       :day-render="renderDay"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <div>
                            <div class="fc-day-number-icon fc-day-number-flight"></div>
                            <span class="text-danger"><sup>*</sup> The day of the flight.</span>
                        </div>
                    </b-col>
                </b-row>
                <b-row v-if="showAirfare">
                    <br /><br />
                </b-row>
                <b-row v-if="showBookedTravel">
                    <b-col>
                        <booked-airfare :table-variant="tableSummaryTableVariant" :trip="selectedTrip"/>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-modal id="airfare-select-modal" scrollable size="lg" footer-bg-variant="dark" header-bg-variant="dark">
            <template #modal-title>
                <div class="text-light"><strong>Request Flight(s) for {{trip.workshop}}</strong></div>
                <div class="text-light"><small class="text-muted">{{trip.startDate | formatDate}} to {{trip.endDate | formatDate}}</small></div>
            </template>
            <b-tabs card v-model="tabIndex" @activate-tab="tabToggle">
                <flight-request-tab title="Flight to the ITC"
                                    ref="toFlightTab"
                                    :selectedDate="toDate"
                                    :accepted="toAccepted"
                                    :other-accepted="fromAccepted"
                                    direction="to" :active="selectedTab === 'to'">

                </flight-request-tab>
                <flight-request-tab title="Flight from the ITC"
                                    ref="fromFlightTab"
                                    :selectedDate="fromDate"
                                    :accepted="fromAccepted"
                                    :other-accepted="toAccepted"
                                    direction="from" :active="selectedTab === 'from'">

                </flight-request-tab>
            </b-tabs>
            <template #modal-footer>
                <b-button-group size="sm">
                    <b-button variant="success" @click="acceptFlights">Accept Flight {{selectedTab}} the ITC</b-button>
                    <b-button size="sm" variant="info" @click="clearFlight">Clear Flight</b-button>
                    <b-button variant="secondary" @click="$bvModal.hide('airfare-select-modal')">Cancel</b-button>
                    <b-button variant="danger" @click="removeFlight" v-if="hasFlightBeenAccepted">Remove Flight</b-button>
                </b-button-group>
            </template>
        </b-modal>
    </b-tab>
</template>
<script>

import  {Vue, Component, Prop} from 'vue-property-decorator';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {Trip, NULL_TRIP, NULL_TRAVELER, FlightRequest, validateFlightRequests} from '@/model/travel';
import {addDays, isEqual as areDatesEqual, isAfter} from 'date-fns';
import CITFDatePicker from '@/components/shared/CITFDatePicker.vue';
import PickerOfDates from '@/components/shared/PickerOfDates';
import FlightRequestTab from '@/views/private/travel/FlightRequestTab.vue';
import {sprintf} from 'sprintf-js';
import _ from 'underscore';
import {date, trimToNull, trimToEmpty, mkDate} from '@/util/formatters';
import $ from 'jquery';
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";
import BookedAirfare from '@/views/private/travel/BookedAirfare';

@Component({
    components: {
        FullCalendar,
        CITFDatePicker,
        FlightRequestTab,
        PickerOfDates,
        BookedAirfare
    },
    filters: {
        formatDate: (d) => date(d, 'M/d/yyyy'),
        flightDirection: f => `${'to' === f.direction ? 'To' : 'From'} the ITC`,
        maleOrFemale: (gender) => {
            switch (trimToEmpty(gender).toUpperCase()) {
                case 'M': return 'Male';
                case 'F': return 'Female';
                default: return '';
            }
        }
    }
})
export default class SelectAirfare extends Vue {
    @Prop({type: String, default: 'Airfare'}) title;
    @Prop({type: Boolean, default: false}) navigable;
    @Prop({type: Boolean, default: false}) disabled;
    @Prop({type: Trip}) trip;
    @Prop({type: Array}) dormStays;

    calendarPlugins = [dayGridPlugin, interactionPlugin];

    gen = null;

    selectedTab = 'to';

    toDate = null;
    fromDate = null;

    toAccepted = null;
    fromAccepted = null;

    errors = [];

    get isDisabled() {
        return this.disabled || !this.isEditable;
    }

    get showAirfare() {
        return this.$store.getters['userSession/isAnAdmin'];
    }

    get hasBookedAirfare() {
        return (this.trip || {}).hasBookedAirfare;
    }

    get showBookedTravel() {
        return this.activeUser.isAnAdministrator() && this.hasBookedAirfare;
    }

    get activeUser() {
        return this.$store.getters['userSession/getUser'];
    }

    get tabIndex() {
        return 'to' === this.selectedTab ? 0 : 1;
    }

    set tabIndex(idx) {
        this.selectedTab = 0 === idx ? 'to' : 'from';
    }

    get traveler() {
        const traveler = !!this.trip && !!this.trip.traveler ? this.trip.traveler : NULL_TRAVELER;
        return traveler;
    }

    get firstName() {
        return this.traveler.workspace.firstName;
    }

    set firstName(fname) {
        this.traveler.workspace.firstName = trimToNull(fname);
        this.confirm = false;
    }

    get middleName() {
        return this.traveler.workspace.middleName;
    }

    set middleName(mname) {
        this.traveler.workspace.middleName = trimToNull(mname);
        this.confirm = false;
    }

    get lastName() {
        return this.traveler.workspace.lastName;
    }

    set lastName(lname) {
        this.traveler.workspace.lastName = trimToNull(lname);
        this.confirm = false;
    }

    get suffix() {
        return this.traveler.workspace.suffix;
    }

    set suffix(sfix) {
        this.traveler.workspace.suffix = trimToNull(sfix);
        this.confirm = false;
    }

    get dateOfBirth() {
        return this.traveler.workspace.dateOfBirth;
    }

    set dateOfBirth(dob) {
        this.traveler.workspace.dateOfBirth = dob;
    }

    get dateOfBirthErrorFeedback() {

        if (!_.isDate(this.dateOfBirth)) {
            return 'Date of Birth is Required.';
        }

        const now = new Date();

        if (isAfter(this.dateOfBirth, now)) {
            return 'Date of Birth cannot be in the future.';
        }

        return '';
    }

    get gender() {
        return this.traveler.workspace.gender;
    }

    set gender(gen) {
        this.traveler.workspace.gender = gen;
    }

    get genderOptions() {
        return [{
            text: 'Male',
            value: 'M'
        }, {
            text: 'Female',
            value: 'F'
        }];
    }

    get confirm() {
        return this.traveler.workspace.nameConfirmed;
    }

    set confirm(confirmed) {
        this.traveler.workspace.nameConfirmed = confirmed;
    }

    get fullName() {
        return this.traveler.workspace.fullName;
    }

    get selectedTrip() {
        return !!this.trip ? this.trip : NULL_TRIP;
    }

    get defaultDate() {
        return this.selectedTrip.startDate;
    }

    get selectedFlightFields() {
        return [{
            key: 'direction',
            label: 'Direction'
        }, {
            key: 'date',
            label: 'Date of Flight'
        }, {
            key: 'departs',
            label: 'Departs'
        }, {
            key: 'arrives',
            label: 'Arrives'
        }];
    }

    get selectedFlightData() {
        return _.chain(['to', 'from'])
            .map((direction) => this.isFlightAccepted(direction) ? this[direction + 'Accepted'] : null)
            .filter((fr) => null !== fr)
            .sortBy((fr) => fr.dateOfFlight)
            .value();
    }

    get events() {

        if (null === this.selectedTrip) {
            return [];
        }

        return _.union([{
            title: this.selectedTrip.workshop,
            start: this.selectedTrip.startDate,
            end: addDays(this.selectedTrip.endDate, 1),
            backgroundColor: '#008CBA',
            borderColor: '#0079A1',
            textColor: '#FFF',
            allDay: true
        }], this.dormStayEvents);
    }

    get dormStayEvents() {
        return _.map(this.dormStays, (stay) => {
            return {
                title: 'Dorm Stay',
                start: stay.checkIn,
                end: stay.checkOut,
                allDay: true,
                borderColor: '#43AC6A',
                backgroundColor: '#43AC6A',
                textColor: '#FFF'
            };
        });
    }

    get isValidSelection() {

        this.errors = [];

        this.errors = validateFlightRequests({
            to: this.toAccepted,
            from: this.fromAccepted
        }, this.dormStays, this.selectedTrip.startDate, this.selectedTrip.endDate);

        return _.isEmpty(this.errors);
    }

    get tableSummaryTableVariant() {
        switch (true) {
            case this.isValidSelection:
                return 'success';

            case this.activeUser.isAnAdministrator():
                return 'warning';

            default:
                return 'danger';
        }
    }

    get tableSummaryErrorCaption() {
        return sprintf('Selected Flight Requests violate CITF Policy.%s',
            this.activeUser.isAnAdministrator() ? ' An exception is necessary.' : ''
        );
    }

    get needsAirfareCaption() {
        return 'TBD indicates airfare reservations are pending completion.';
    }

    get hasFlightBeenAccepted() {
        return this.isFlightAccepted(this.selectedTab);
    }


    isFlightAccepted(direction) {
        return this[direction + 'Accepted'] instanceof FlightRequest;
    }


    hasError(field) {
        switch (field) {
            case 'firstName':
            case 'lastName':
                return _.isEmpty((this[field] || '').trim());

            case 'dateOfBirth':
                return !_.isDate(this[field]) || new Date() <= this[field];
                // const now = new Date();
                // return !_.isDate(this[field]) || isAfter(this[field], now);

            case 'gender':
                return !_.contains(['M', 'F'], this[field]);

            case 'confirm':
                return !this.confirm;
        }
    }

    onClick(cell) {
        //Don't allow edit if a flight has already been booked or trip has started/is in past
        if (!this.isEditable || this.disabled) {
            return;
        }
        const selectedDate = cell.date;
        const toDate = (this.toAccepted || {}).dateOfFlight;
        const toSelected = _.isDate(toDate);
        const fromDate = (this.fromAccepted || {}).dateOfFlight;
        const fromSelected = _.isDate(fromDate);
        const toIsSelected = areDatesEqual(selectedDate, toDate);
        const fromIsSelected = areDatesEqual(selectedDate, fromDate);

        if (toSelected && fromSelected) {

            // The date that was clicked on must be one of the selected dates
            if (!toIsSelected && !fromIsSelected) {
                return; // Prevents the modal dialog from being opened.
                        // TODO: Consider alerting the user they clicked on an invalid day
            }

            this.selectedTab = toIsSelected ? 'to' : 'from';

            return this.openAirfareModal();
        }

        if (toSelected) {

            this.selectedTab = toIsSelected ? 'to' : 'from';

            if (!toIsSelected) {
                // The 'to' flight has been accepted, 'from' has not, and some other date, besides 'to' has been clicked
                this.fromDate = selectedDate;
            }

            return this.openAirfareModal();
        }

        if (fromSelected) {

            this.selectedTab = fromIsSelected ? 'from' : 'to';

            if (!fromIsSelected) {
                // The 'from' flight has been accepted, 'to' has not, and some other date, besides 'from' has been clicked
                this.toDate = selectedDate;
            }

            return this.openAirfareModal();
        }

        this.selectedTab = 'to';
        this.toDate = selectedDate;

        this.openAirfareModal();
    }

    openAirfareModal() {
        this.$bvModal.show('airfare-select-modal');
    }

    closeAirfareModal() {
        this.$bvModal.hide('airfare-select-modal');
    }

    removeFlight() {
        this.$refs[this.selectedTab + 'FlightTab'].reset();
        this[this.selectedTab + 'Accepted'] = null;
        this.reRender()
        this.closeAirfareModal();
    }

    async acceptFlights() {

        try {

            const direction = this.selectedTab;
            const flight = this.$refs[direction + 'FlightTab'].getRequest();
            this[direction + 'Accepted'] = flight;

            if (flight instanceof FlightRequest) {
                flight.direction = direction;
                flight.exception = !this.isValidSelection;
            }

        } catch (error) {
            this.tabIndex = 0;
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }

        this.reRender();
        this.closeAirfareModal();

        const flightRequests = {};

        if (this.toAccepted instanceof FlightRequest) {
            flightRequests.to = this.toAccepted;
        }

        if (this.fromAccepted instanceof FlightRequest) {
            flightRequests.from = this.fromAccepted;
        }

        this.$emit('flight-requests-selected', flightRequests);
    }

    renderDay(cell) {
        this.addStyling(cell.el);
    }

    reRender() {
        const _this = this;
        $('td.fc-day')
            .removeClass('fc-day-number-selected')
            .html('')
            .each((idx, e) => {
                _this.addStyling(e);
            })

    }

    addStyling(element) {
        const elem = $(element);
        const day = mkDate(elem.data('date'));
        const toDate = (this.toAccepted || {}).dateOfFlight;
        const fromDate = (this.fromAccepted || {}).dateOfFlight;

        const isToDate = areDatesEqual(day, toDate);
        const isFromDate = areDatesEqual(day, fromDate);

        if (isToDate || isFromDate) {
            elem.addClass('fc-day-number-selected')
                .html('<div class="flight-request fc-day-number-icon fc-day-number-flight"></div>');
        }
    }

    calendarRendered() {
        // TODO: try to add some tooltips, perhaps?
    }

    clearFlight() {
        this.$refs[this.selectedTab + 'FlightTab'].clear();
    }

    getAirport(airportId) {
        return this.$store.getters['travel/getAirportById'](airportId);
    }

    getState(stateId) {
        return this.$store.getters['common/getStateById'](stateId);
    }

    tabToggle(idx) {
        if (this.isFlightAccepted('to') || this.isFlightAccepted('from')) {
            return;
        }

        if (0 === idx) { // The 'to' tab has been selected ...
            this.$refs.toFlightTab.dateOfFlight = this.$refs.fromFlightTab.dateOfFlight;
            this.$refs.fromFlightTab.reset();
        }
        else if (1 === idx) { // The 'from' tab has been selected ...
            this.$refs.fromFlightTab.dateOfFlight = this.$refs.toFlightTab.dateOfFlight;
            this.$refs.toFlightTab.reset();
        }
    }

    reset() {
        this.toAccepted = null;
        this.fromAccepted = null;
        this.toDate = null;
        this.fromDate = null;
        this.errors = [];

        this.reRender();

        this.$emit('flight-requests-selected', {});
    }

    get hasFlightRequest() {
        return !this.trip ? false : this.trip.hasFlightRequest;
    }

    get isEditable() {
        const trip = this.trip || {};
        return !this.activeUser.isATrainee() && (0 === trip.id || (!this.hasBookedAirfare && !trip.isPastTrip));
    }

    async mounted() {
        try {
            await this.$store.dispatch('travel/loadAirports');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
        if (this.trip instanceof Trip) {
            const frs = this.trip.flightRequests || {};
            this.toAccepted = frs.to;
            this.fromAccepted = frs.from;
            this.confirm = true;
        }
    }
}

</script>
<style scoped>
    strong.red {
        font-weight: normal;
        color: #F00;
    }

    strong.italic {
        font-style: italic;
    }

    #airfare-select-modal {
        z-index: 10040;
    }
</style>