<template>
    <b-tab :title="title" :active="active" lazy>
        <b-row>
            <b-col cols="md-6">
                <b-form-group label="Departure City:" :state="fieldState('dCity')" invalid-feedback="Departure City is Required.">
                    <b-input size="sm" v-model="departureCity" :state="fieldState('dCity')" tabindex="1"/>
                </b-form-group>
            </b-col>
            <b-col cols="md-6">
                <b-form-group label="Arrival City:" :state="fieldState('aCity')" invalid-feedback="Arrival City is Required.">
                    <b-input size="sm" v-model="arrivalCity" :state="fieldState('aCity')" tabindex="4"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="md-6">
                <b-form-group label="Departure State:" :state="fieldState('dStateId')" invalid-feedback="Departure State is Required.">
                    <b-select size="sm" :options="stateOptions" v-model="departureStateId" :state="fieldState('dStateId')" tabindex="2">
                        <template v-slot:first>
                            <option :value="null"> - </option>
                        </template>
                    </b-select>
                </b-form-group>
            </b-col>
            <b-col cols="md-6">
                <b-form-group label="Arrival State:" :state="fieldState('aStateId')" invalid-feedback="Arrival State is Required.">
                    <b-select size="sm" :options="stateOptions" v-model="arrivalStateId" :state="fieldState('aStateId')" tabindex="5">
                        <template v-slot:first>
                            <option :value="null"> - </option>
                        </template>
                    </b-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="md-6">
                <b-form-group label="Departure Airport:" :state="fieldState('dAirportId')" invalid-feedback="Departure Airport is Required.">
                    <b-select size="sm" :options="departureAirportOptions" v-model="departureAirportId" :state="fieldState('dAirportId')" tabindex="3">
                        <template v-slot:first>
                            <option :value="null"> - </option>
                        </template>
                    </b-select>
                </b-form-group>
            </b-col>
            <b-col cols="md-6">
                <b-form-group label="Arrival Airport:" :state="fieldState('aAirportId')" invalid-feedback="Arrival Airport is Required.'">
                    <b-select size="sm" :options="arrivalAirportOptions" v-model="arrivalAirportId" :state="fieldState('aAirportId')" tabindex="6">
                        <template v-slot:first>
                            <option :value="null"> - </option>
                        </template>
                    </b-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="md-6">
                <b-form-group label="Preferred Flight Time:">
                    <b-select size="sm" v-model="preferredFlightTime" :options="flightTimeOptions" tabindex="7"/>
                </b-form-group>
            </b-col>
            <b-col cols="md-6">
                <b-form-group label="Date of Flight:" :state="fieldState('dateOfFlight')" invalid-feedback="Date of Flight is Required.">
                    <date-picker v-model="flightDate" format="M/D/YYYY" :state="fieldState('dateOfFlight')" tabindex="8"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group label="Additional Requests:">
                    <b-textarea size="sm" v-model="additionalRequests" tabindex="9"/>
                </b-form-group>
            </b-col>
        </b-row>
    </b-tab>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import _ from 'underscore';
import {sprintf} from 'sprintf-js';
import CITFDatePicker from '@/components/shared/CITFDatePicker';
import {FlightRequest} from '@/model/travel';

@Component({
    components: {
        'date-picker': CITFDatePicker
    }
})
export default class FlightRequestTab extends Vue {

    @Prop({type: String}) title;
    @Prop({type: String}) direction;
    @Prop({type: Boolean, default: false}) active;
    @Prop({type: Date}) selectedDate;
    @Prop({type: FlightRequest}) accepted;
    @Prop({type: FlightRequest}) otherAccepted;

    dCity = null;
    dStateId = null;
    dAirportId = null;
    aCity = null;
    aStateId = null;
    aAirportId = null;
    preferredFlightTime = 'No Preference';
    dateOfFlight = null;
    additionalRequests = null;

    get nevadaId() {
        const nevada = _.find(this.states, (s) => s.stateAbbreviation === 'NV');
        return (nevada || {}).id;
    }

    get mccarranId() {
        const mccarran = _.find(this.airports, (a) => a.iata === 'LAS');
        return (mccarran || {}).id;
    }

    get departureCity() {

        if ('from' === this.direction && _.isEmpty(this.dCity)) {
            this.dCity = 'Las Vegas';
        }

        return this.dCity;
    }

    set departureCity(city) {
        this.dCity = city;
    }

    get departureStateId() {

        if ('from' === this.direction && null === this.dStateId) {
            this.dStateId = this.nevadaId;
        }

        return this.dStateId;
    }

    set departureStateId(stateId) {
        this.dStateId = stateId;
    }

    get departureAirportId() {

        if ('from' === this.direction && null === this.dAirportId && this.nevadaId === this.dStateId) {
            this.dAirportId = this.mccarranId;
        }

        return this.dAirportId;
    }

    set departureAirportId(airportId) {
        this.dAirportId = airportId;
    }

    get arrivalCity() {

        if ('to' === this.direction && _.isEmpty(this.aCity)) {
            this.aCity = 'Las Vegas';
        }

        return this.aCity;
    }

    set arrivalCity(city) {
        this.aCity = city;
    }

    get arrivalStateId() {

        if ('to' === this.direction && null === this.aStateId) {
            this.aStateId = this.nevadaId;
        }

        return this.aStateId;
    }

    set arrivalStateId(stateId) {
        this.aStateId = stateId;
    }

    get arrivalAirportId() {

        if ('to' === this.direction && null === this.aAirportId && this.nevadaId === this.aStateId) {
            this.aAirportId = this.mccarranId;
        }

        return this.aAirportId;
    }

    set arrivalAirportId(airportId) {
        this.aAirportId = airportId;
    }

    get flightDate() {
        return this.dateOfFlight;
    }

    set flightDate(date) {
        this.dateOfFlight = date;
    }

    get states() {
        return this.$store.getters['common/states'];
    }

    get airports() {

        return _.chain(this.$store.getters['travel/airports'])
            .filter((a) => !a.hidden)
            .value();
    }

    get stateOptions() {

        const states = _.groupBy(this.states, (s) => s.countryAbbreviation);

        const usOptions = _.map(states.US, (s) => {
            return {
                value: s.id,
                text: s.state + ' (' + s.stateAbbreviation + ')'
            };
        });

        const caOptions = _.map(states.CA, (s) => {
            return {
                value: s.id,
                text: s.state + ' (' + s.stateAbbreviation + ')'
            };
        });

        return [{
            label: 'United States of America',
            options: usOptions
        }, {
            label: 'Canada',
            options: caOptions
        }];
    }

    get departureAirportOptions() {
        return this.getAirportOptions(this.departureStateId);
    }

    get arrivalAirportOptions() {
        return this.getAirportOptions(this.arrivalStateId);
    }

    get flightTimeOptions() {
        return [
            'No Preference',
            'Early Morning (Before 9 a.m.)',
            'Late Morning (9 a.m. to 12 p.m.)',
            'Afternoon (12 p.m. to 6:30 p.m.)',
            'Evening (6:30 p.m. to 9 p.m.)',
            'Red-Eye (After 9 p.m.)'
        ];
    }

    getAirportOptions(stateId) {
        return _.map(this.getAirports(stateId), (a) => {
            return {
                value: a.id,
                text: sprintf('%s (%s)', a.name, a.iata)
            };
        });
    }

    getAirports(stateId) {

        return _.chain(this.$store.getters['travel/airports'])
            .filter((a) => !a.hidden)
            .filter((a) => a.stateId === stateId)
            .sortBy((a) => a.name)
            .sortBy((a) => -1 * a.priority)
            .value();
    }

    clear() {
        this.dCity = null;
        this.dStateId = null;
        this.dAirportId = null;
        this.aCity = null;
        this.aStateId = null;
        this.aAirportId = null;
        this.preferredFlightTime = 'No Preference';
        this.dateOfFlight = this.selectedDate;
    }

    isEmptyRequest() {

        // If there is a flight date selected ...
        return null === this.dateOfFlight && (() => {

            // ... or any of the following ...
            if ('to' === this.direction) {
                // If the 'to' tab is selected
                // We need depart city, state, & airport
                // If /all/ three of these are empty, we can consider this an empty flight - e.g. not selected
                return _.isEmpty(this.dCity) && null === this.dStateId && null === this.dAirportId;
            } else {
                // If the 'from' tab is selected
                // We need arrive city, state & airport
                // If /all/ three of these are empty, we can consider this an empty flight - e.g. not selected
                return _.isEmpty(this.aCity) && null === this.aStateId && null === this.aAirportId;
            }
        })();
        // ... then the flight request isn't empty
    }

    fieldState(field) {

        if (this.isEmptyRequest()) {
            return null;
        }

        switch (true) {
            case /city/i.test(field):
                return !_.isEmpty(this[field]);

            case /stateId/i.test(field):
                return _.isNumber(this[field]);
            case /airportId/i.test(field):
                const a = this.$store.getters['travel/getAirportById'](this[field]);
                const leg = field.replace(/^(a|d)AirportId$/, '$1');
                return (a || {}).stateId === this[leg + 'StateId'];

            case 'dateOfFlight' === field:
                return _.isDate(this.dateOfFlight);

            default:
                return true;
        }
    }

    validateRequest() {

        const valid = _.all([
            'dCity',
            'dStateId',
            'dAirportId',
            'aCity',
            'aStateId',
            'aAirportId',
            'dateOfFlight'
        ], (f) => this.fieldState(f));

        if (!valid) {
            throw new Error('Please correct the highlighted errors.');
        }
    }

    getRequest() {

        if (this.isEmptyRequest()) {
            return null;
        }

        this.validateRequest();

        return FlightRequest.create({
            departCity: this.dCity,
            departStateId: this.dStateId,
            departAirportId: this.dAirportId,
            arriveCity: this.aCity,
            arriveStateId: this.aStateId,
            arriveAirportId: this.aAirportId,
            preferredFlightTime: this.preferredFlightTime,
            dateOfFlight: this.dateOfFlight,
            additionalRequests: this.additionalRequests,
            cancelable: true
        });
    }

    reset() {
        this.dCity = null;
        this.dStateId = null;
        this.dAirportId = null;
        this.aCity = null;
        this.aStateId = null;
        this.aAirportId = null;
        this.preferredFlightTime = 'No Preference';
        this.dateOfFlight = null;
        this.additionalRequests = null;
    }

    mounted() {
        this.clear();

        if (this.accepted instanceof FlightRequest) {
            this.dCity = this.accepted.departCity;
            this.dStateId = this.accepted.departStateId;
            this.dAirportId = this.accepted.departAirportId;
            this.aCity = this.accepted.arriveCity;
            this.aStateId = this.accepted.arriveStateId;
            this.aAirportId = this.accepted.arriveAirportId;
            this.preferredFlightTime = this.accepted.preferredFlightTime;
            this.dateOfFlight = this.accepted.dateOfFlight;
            this.additionalRequests = this.accepted.additionalRequests;
        }

        if (!(this.accepted instanceof FlightRequest) && // This direction has not yet been selected
            this.otherAccepted instanceof FlightRequest
        ) {
            // We want to copy over reverse values.   If they flew from A -> B, then the opposite direction
            // will likely be B -> A

            this.dCity = this.otherAccepted.arriveCity;
            this.dStateId = this.otherAccepted.arriveStateId;
            this.dAirportId = this.otherAccepted.arriveAirportId;
            this.aCity = this.otherAccepted.departCity;
            this.aStateId = this.otherAccepted.departStateId;
            this.aAirportId = this.otherAccepted.departAirportId;
            this.additionalRequests = this.otherAccepted.additionalRequests;
        }
    }
}

</script>
<style scoped>

</style>