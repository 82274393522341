<template>
    <b-tab title="Dorm Room" :disabled="!navigable" lazy>
        <b-row>
            <b-col cols="md-5">
                <b-row>
                    <b-col class="sticky taped">
                        <template v-if="disabled">
                            The highlighted days indicate when {{trip | travelerName}} will be staying at the ITC.
                        </template>
                        <template v-else>
                            Click on the days {{trip | travelerName}} will be staying in the ITC dorm rooms.
                        </template>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :state="!hasErrors()" invalid-feedback="Selected Dorm Stays violate CITF Policy.">
                            <b-table-lite small striped borderless outlined head-variant="dark" :table-variant="tableVariant"
                                          :fields="tableFields"
                                          :items="dormStays">
                                <template #custom-foot v-if="!disabled && 0 < dormStays.length && !pastTrip">
                                    <b-td variant="secondary" colspan="2" class="clearfix">
                                        <b-button class="float-right" size="sm" variant="info" @click="reset">Reset</b-button>
                                    </b-td>
                                </template>
                            </b-table-lite>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <template v-if="activeUser.isAnAdministrator()">
                            <b-form-group label="Additional Room Requests:">
                                <b-textarea size="sm" v-model="additionalRequests"/>
                            </b-form-group>
                        </template>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="md-7">
                <b-row>
                    <b-col>
                        <full-calendar ref="dormRoomCalendar"
                                       :plugins="calendarPlugins"
                                       :events="events"
                                       :default-date="defaultDate"
                                       @dateClick="onClick"
                                       :day-render="renderDay"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <div>
                            <div class="fc-day-number-icon fc-day-number-check-in"></div>
                            <span class="text-danger"><sup>*</sup> The day you will check-in to the dorm room.</span>
                        </div>
                        <div>
                            <div class="fc-day-number-icon fc-day-number-check-out"></div>
                            <span class="text-danger"><sup>*</sup> The day you will check-out of the dorm room.</span>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-tab>
</template>
<script>

import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {Trip, NULL_TRIP, DormStay} from '@/model/travel';
import {addDays, subDays, differenceInDays, isBefore, isAfter} from 'date-fns';
import _ from 'underscore';
import {sprintf} from 'sprintf-js';
import {date, mkDate} from '@/util/formatters';
import $ from 'jquery';

@Component({
    components: {
        FullCalendar
    },

    filters: {
        travelerName: (trip) => {
            const traveler = (trip || {}).traveler || {};
            return sprintf('%s %s', traveler.firstName, traveler.lastName);
        }
    }
})
export default class SelectDormRoom extends Vue {

    @Prop({type: Boolean, default: false}) navigable;
    @Prop({type: Trip}) trip;
    @Prop({type: Boolean, default: false}) disabled;

    calendarPlugins = [dayGridPlugin, interactionPlugin];

    selectedDays = [];

    additionalRequests = null;

    get selectedTrip() {
        return this.trip || {};
    }

    get pastTrip() {
        return _.isDate(this.selectedTrip.endDate) && isAfter(new Date(), this.selectedTrip.endDate);
    }

    get defaultDate() {
        return this.selectedTrip.startDate;
    }

    get events() {

        if (null === this.selectedTrip) {
            return [];
        }

        return [{
            title: this.selectedTrip.workshop,
            start: this.selectedTrip.startDate,
            end: addDays(this.selectedTrip.endDate, 1),
            allDay: true,
            backgroundColor: '#008CBA',
            borderColor: '#0079A1',
            textColor: '#FFF'
        }];
    }

    get dormStays() {

        let rangeStart = null;
        let rangeEnd = null;
        const ranges = [];

        _.chain(this.selectedDays)
            .sortBy((d) => d)
            .each((d) => {

                const current = mkDate(d);

                if (null === rangeStart) {
                    rangeStart = mkDate(current);
                    rangeEnd = mkDate(current);
                    return;
                }

                if (1 === Math.abs(differenceInDays(current, rangeEnd))) {
                    rangeEnd = current;
                    return;
                }

                ranges.push(ranges.push(this.flushRange(rangeStart, rangeEnd)));

                rangeStart = current;
                rangeEnd = current;
            });

        if (null !== rangeStart) {
            ranges.push(this.flushRange(rangeStart, rangeEnd));
        }

        return _.filter(ranges, _.isObject);
    }

    get tableFields() {
        return [{
            key: 'checkIn',
            label: 'Check-In',
            formatter: (d) => date(d, 'M/d/yyyy')
        }, {
            key: 'checkOut',
            label: 'Check-Out',
            formatter: (d) => date(d, 'M/d/yyyy')
        }];
    }

    get tableVariant() {

        switch (true) {
            case !this.hasErrors(): return 'success';
            case this.activeUser.isAnAdministrator(): return 'warning';
            default: return 'danger';
        }
    }

    get activeUser() {
        return this.$store.getters['userSession/getUser'];
    }

    flushRange(checkIn, checkOut) {
        return DormStay.create({
            checkIn,
            checkOut: addDays(checkOut, 1),
            additionalRequests: this.additionalRequests
        });
    }

    @Watch('trip')
    tripChange(trip) {
        this.setDays(trip);
        this.$nextTick(() => this.reRender());
    }

    onClick(cell) {

        if (this.disabled) {
            return;
        }

        const d = cell.dateStr;
        const idx = this.selectedDays.indexOf(d);

        if (idx >= 0) {
            this.selectedDays.splice(idx, 1);
        } else {
            this.selectedDays.push(d);
        }

        this.reRender();

        if (this.hasErrors()) {
            this.$emit('dorm-stays-selected', this.activeUser.isAnAdministrator() ? this.dormStays : []);
        } else {
            this.$emit('dorm-stays-selected', this.dormStays);
        }
    }

    setDays(trip) {
        if (trip instanceof Trip) {
            this.selectedDays = [];

            _.each(trip.dormStays, (ds) => {

                this.additionalRequests = ds.additionalRequests;

                this.selectedDays.push(date(ds.checkIn));

                let d = addDays(ds.checkIn, 1);

                while (isBefore(d, ds.checkOut)) {
                    this.selectedDays.push(date(d));
                    d = addDays(d, 1);
                }
            });
        }
    }

    reRender() {
        const _this = this;

        $('td.fc-day')
            .removeClass('fc-day-number-selected')
            .html('')
            .each((idx, e) => {
                _this.addStyling(mkDate($(e).data('date')), e);
            });
    }

    renderDay(cell) {
        this.addStyling(cell.date, cell.el);
    }

    addStyling(day, element) {
        const elem = $(element);
        const d = date(day);
        const dayBefore = date(subDays(day, 1));

        if (_.contains(this.selectedDays, d)) {
            elem.addClass('fc-day-number-selected');

            if (!_.contains(this.selectedDays, dayBefore)) {
                elem.html('<div class="fc-day-number-icon fc-day-number-check-in"></div>');
            }
        } else if (_.contains(this.selectedDays, dayBefore)) {
            elem.html('<div class="fc-day-number-icon fc-day-number-check-out"></div>');
        }
    }

    hasErrors() {

        if (null === this.selectedTrip || NULL_TRIP.equals(this.selectedTrip)) {
            return true;
        }

        return _.any(this.dormStays, (stay) => {
            const startDiff = differenceInDays(this.selectedTrip.startDate, stay.checkIn);
            const endDiff = differenceInDays(stay.checkOut, this.selectedTrip.endDate);

            return 1 < startDiff || 1 < endDiff;
        });
    }

    reset() {

        if (this.disabled) {
            return;
        }

        this.selectedDays = [];

        this.$emit('dorm-stays-selected', []);

        this.reRender();
    }
}
</script>
<style scoped>

</style>
<style scoped xml:lang="scss">
    @import '~@fullcalendar/core/main.css';
    @import '~@fullcalendar/daygrid/main.css';
</style>
