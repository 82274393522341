<template>
    <div>
        <b-tab title="Register"
               :disabled="!navigable"
               lazy>
            <b-table ref="pending-registrants"
                     striped
                     borderless
                     small
                     stacked="md"
                     head-variant="dark"
                     :fields="fields"
                     :items="registrableUsers">
                <template v-slot:cell(user)="row">{{row.item.userLabel}}</template>
                <template v-slot:cell(action)="row">
                    <b-button
                            variant="primary"
                            size="sm"
                            @click="registerUser(row.item)">Register</b-button>
                </template>
            </b-table>
        </b-tab>
        <!-- Trainee/Mentor Registration -->
        <b-modal :id="userRegistrationModalId"
                 size="xl"
                 scrollable
                 title="Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <UserRegistration
                    :fromTravel="true"
                    :user="selectedUser"
                    @done="closeUserRegistration" />
        </b-modal>
        <!-- Instructor Registration -->
        <b-modal :id="instructorRegistrationModalId"
                 size="xl"
                 scrollable
                 title="Registration"
                 header-bg-variant="dark"
                 header-text-variant="white">
            <InstructorSessionRegistration
                    :fromTravel="true"
                    :instructor="selectedUser"
                    @done="closeInstructorRegistration" />
        </b-modal>
    </div>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import UserRegistration from '@/views/private/users/UserRegistration.vue';
import InstructorSessionRegistration from '@/views/private/instructor/InstructorSessionRegistration.vue';
import User, {NULL_USER} from "@/model/user";
import store from '@/store/store';
import {sprintf} from 'sprintf-js';
import _ from "underscore";
import {errorToastOptions} from "../../../util/formatters";

@Component({
    components: {
        UserRegistration,
        InstructorSessionRegistration
    },
    asyncComputed: {
        registrableUsers: {
            async get() {
                if (!User.isValidUser(this.activeUser)) {
                    return [];
                }
                if (this.activeUser.isAnInstructor()) {
                    //Instructors can only register for themselves
                    //Instructor registrability is limited to enabled status, no need to load registrable IDs for check
                    return this.activeUser.isEnabled ? [this.activeUser] : [];
                }
                else if (this.activeUser.isAMentor()) {
                    //Mentors can only register for themselves and their trainees, plus a bunch of other rules
                    try {
                        await store.dispatch('registration/loadRegistrableUserIds');
                        await store.dispatch('users/loadAvailableTrainees');
                    }
                    catch (error) {
                        this.$bvToast.toast(error.message, errorToastOptions);
                    }
                    const registrableUserIds = store.getters['registration/registrableUserIds'];
                    const availableUsers = store.getters['users/availableTrainees'];
                    availableUsers.push(this.activeUser);
                    const filteredUsers = _.chain(availableUsers)
                        .filter(user => {
                            return registrableUserIds.includes(user.id);
                        })
                        .sortBy(user => {
                            return user.fullname;
                        })
                        .value();
                    return filteredUsers;
                }
                else {
                    return [];
                }
            }
        }
    },
    methods: {
        refreshUsers() {
            this.$asyncComputed.registrableUsers.update();
        }
    }
})

export default class TravelRegistrations extends Vue {
    @Prop({type: Boolean, default: false}) navigable;
    selectedUser = NULL_USER.clone();

    async mounted() {
        //Since active user is a possible registrant, ensure registrations are up to date
        try {
            await this.$store.dispatch('userSession/loadProfile');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
        this.refreshUsers();
    }

    resetSelectedUser() {
        this.selectedUser = NULL_USER.clone();
    }

    get userRegistrationModalId() {
        if (!User.isValidUser(this.selectedUser)) {
            return 'user-registration-modal-invalid';
        }
        else {
            return sprintf('user-registration-modal-%d', this.selectedUser.id);
        }
    }

    closeUserRegistration(details) {
        this.$bvModal.hide(this.userRegistrationModalId);
        this.resetOrRoute(details);
    }

    closeInstructorRegistration(details) {
        this.$bvModal.hide(this.instructorRegistrationModalId);
        this.resetOrRoute(details);
    }

    resetOrRoute(details) {
        //If details are missing or no travel is needed, refresh post-registration data
        if (!details || !details.travelNeeded) {
            this.resetSelectedUser();
            this.refreshUsers();
        }
        //If details are present, and travel is needed now, route via parent
        if (!!details && !!details.travelNeeded && !!details.doItNow) {
            this.$emit('registered', {
                attendeeId: details.attendeeId,
                registrationId: details.registrationId
            });
        }
    }

    get instructorRegistrationModalId() {
        if (!User.isValidUser(this.selectedUser)) {
            return 'instructor-registration-modal-invalid';
        }
        else {
            return sprintf('instructor-registration-modal-%d', this.selectedUser.id);
        }
    }

    registerUser(user) {
        if (User.isValidUser(user)) {
            this.selectedUser = user;
            this.$nextTick(() => this.$bvModal.show(user.isAnInstructor() ? this.instructorRegistrationModalId : this.userRegistrationModalId));
        }
        else {
            console.log('ERROR - Invalid User Selection!');
            console.log(user);
        }
    }

    get activeUser() {
        return this.$store.getters['userSession/getUser'];
    }

    get fields() {
        return [{
            key: 'user',
            label: 'User',
            sortable: true
        }, {
            key: 'action',
            label: 'Action',
            sortable: false
        }];
    }
}

</script>
<style scoped>

</style>