<template>
    <b-tab title="Travel Policy" :disabled="!navigable" lazy>
        <b-row>
            <b-col v-html="travelPolicy"/>
        </b-row>
        <b-row>
            <b-col><hr/></b-col>
        </b-row>
        <b-row>
            <b-col cols="6">
                <b-form-group class="yesNoQuestion" label="Do you understand and accept the travel policy?">
                    <b-form-radio-group v-model="understandsTravelPolicy"
                                        :options="yesNoOptions"
                                        button-variant="outline-primary"
                                        size="sm"
                                        buttons/>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-row>
                    <b-col>
                        <b-form-group class="yesNoQuestion" label="Will you need a dorm room?">
                            <b-form-radio-group v-model="needsDormRoom"
                                                :options="yesNoOptions"
                                                button-variant="outline-primary"
                                                size="sm"
                                                buttons/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group class="yesNoQuestion" label="Will you need airfare?">
                            <b-form-radio-group v-model="needsAirfare"
                                                :options="yesNoOptions"
                                                button-variant="outline-primary"
                                                size="sm"
                                                buttons/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-tab>
</template>
<script>

import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class TravelPolicy extends Vue {

    @Prop({type: Boolean, default: true}) navigable;
    @Prop({type: Boolean}) policyAgreedTo;
    @Prop({type: Boolean}) dormRoom;
    @Prop({type: Boolean}) airfare;

    get travelPolicy() {
        return this.$store.getters['travel/policy'];
    }

    get understandsTravelPolicy() {
        return this.policyAgreedTo;
    }

    set understandsTravelPolicy(yesClicked) {
        this.$emit('question-answered', 'policyAgreedTo', yesClicked);
    }

    get needsDormRoom() {
        return this.dormRoom;
    }

    set needsDormRoom(yesClicked) {
        this.$emit('question-answered', 'needsDormRoom', yesClicked);
    }

    get needsAirfare() {
        return this.airfare;
    }

    set needsAirfare(yesClicked) {
        this.$emit('question-answered', 'needsAirfare', yesClicked);
    }


    get yesNoOptions() {
        return [
            {text: 'Yes', value: true},
            {text: 'No', value: false}
        ];
    }
}

</script>
<style scoped>
    fieldset.yesNoQuestion {
        font-weight: bold;
    }
</style>