<template>
    <b-tab title="Select Traveler" :active="active" lazy>
        <b-row>
            <b-col md="6">
                <b-card>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group size="sm" label="Traveler Name">
                                <b-input size="sm" placeholder="Traveler Name" v-model="nameFilter" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group size="sm" label="Security Level">
                                <b-input-group>
                                    <b-select size="sm" v-model="securityLevelFilter" :options="securityLevels" />
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-select ref="pending-travelers"
                                           borderless small selectable stacked="md"
                                           select-mode="single"
                                           :select-size="20"
                                           :options="pendingTravelers"
                                           v-model="selectedTraveler"/>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col md="6">
                <b-card :title="selectedTraveler.isValid ? selectedTraveler.travelerName : 'Select a Traveler'">
                    <b-row>
                        <b-col>
                            <b-form-select ref="pending-trips"
                                           borderless small selectable stacked="md"
                                           select-mode="single"
                                           :select-size="20"
                                           :options="pendingTrips"
                                           v-model="selectedTrip"/>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </b-tab>
</template>
<script>

import {formatUbcIdNoDash} from '@/components/shared/UbcId';
import {Vue, Prop, Component, Watch} from 'vue-property-decorator';
import _ from 'underscore';
import travelDao from '@/dao/travel_dao';
import {Traveler, Trip, NULL_TRAVELER, NULL_TRIP} from '@/model/travel';
import {sprintf} from "sprintf-js";
import {trimToEmpty, trimToNull, date} from '@/util/formatters';
import {SecurityLevel} from '@/model/security_level';
import formatters from '@/util/formatters';
import {errorToastOptions} from "../../../util/formatters";

@Component({
    filters: {
        fullname: (traveler) => {
            const fname = trimToEmpty(traveler.firstName);
            const mname = trimToNull(traveler.middleName);
            const lname = trimToEmpty(traveler.lastName);
            const suffix = trimToNull(traveler.suffix);
            return sprintf('%s%s %s%s',
                fname,
                !mname ? '' : ' ' + mname,
                lname,
                !suffix ? '' : ' ' + suffix
            );
        }
    }
})
export default class SelectTraveler extends Vue {
    @Prop({type: Boolean, default: false}) active;
    @Prop({type: Trip}) value;
    @Prop({type: [String, Number]}) attendeeId;
    @Prop({type: [String, Number]}) registrationId;

    nameFilter = null;
    securityLevelFilter = null;
    traveler = null;
    trips = [];

    async mounted() {
        try {
            await this.$store.dispatch('travel/loadPendingTravelers');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    updated() {
        const attendeeId = parseInt(this.attendeeId, 10) || 0;
        const registrationId = parseInt(this.registrationId, 10) || 0;
        // console.log(attendeeId);
        // console.log(registrationId);
        if (0 < attendeeId) {
            //Select traveler
            const traveler = _.findWhere(this.allPendingTravelers, {attendeeId: attendeeId});
            // console.log(traveler);
            if (!!traveler) {
                this.selectedTraveler = traveler;
                // console.log(this.selectedTraveler);
                if (0 < registrationId) {
                    //Select trip
                    const trip = _.findWhere(this.pendingTrips, {registrationId: registrationId});
                    // console.log(trip);
                    if (!!trip) {
                        this.selectedTrip = trip.value;
                        // console.log(this.selectedTrip);
                    }
                }
            }
        }
    }

    get securityLevels() {
        const securityLevels = _.chain(SecurityLevel.getLevels())
            .map((l) => {
                return {
                    value: l,
                    text: l.role
                };
            })
            .sortBy((o) => o.value.ord)
            .value();
        securityLevels.unshift({
            value: null,
            text: '-'
        });
        return securityLevels;
    }

    get allPendingTravelers() {
        return this.$store.getters['travel/pendingTravelers'];
    }

    get pendingTravelers() {
        const travelers = _.chain(this.allPendingTravelers)
            .filter((t) => {
                const filter = _.isEmpty(this.nameFilter) ? null : new RegExp(this.nameFilter, 'i');
                return null === filter || filter.test(t.firstName) || filter.test(t.lastName);
            })
            .filter((t) => {
                return null === this.securityLevelFilter || _.isEqual(this.securityLevelFilter, t.securityLevel);
            })
            .sortBy((t) => t.firstName)
            .sortBy((t) => t.lastName)
            .map((t) => {
                return {
                    text: sprintf('%s, %s (%s) (%s)', t.lastName, t.firstName,
                        _.isEmpty(t.ubcId) ? 'Not a UBC Member' : formatUbcIdNoDash(t.ubcId), t.securityLevel),
                    value: t
                };
            })
            .value();
        return travelers;
    }

    get selectedTraveler() {
        return this.traveler instanceof Traveler ? this.traveler : NULL_TRAVELER;
    }

    set selectedTraveler(traveler) {
        this.traveler = traveler instanceof Traveler ? traveler : null;
    }

    @Watch('traveler')
    async travelerSelected() {
        this.trips = [];
        if (!this.traveler || !this.traveler instanceof Traveler) {
            return;
        }
        this.$emit('traveler-changed');
        this.refreshTrips();
    }

    async refreshTrips() {
        const id = parseInt(this.selectedTraveler.id, 10) || null;
        if (!!id) {
            try {
                this.trips = await travelDao.getPendingTrips(this.selectedTraveler.id);
                if (!!this.registrationId) {
                    const trip = _.find(this.trips, (t) => {
                        const tRId = parseInt(t.registrationId, 10) || 0;
                        const sRId = parseInt(this.registrationId, 10) || 0;
                        return tRId !== 0 && tRId === sRId;
                    });
                    if (trip instanceof Trip) {
                        this.selectedTrip = trip;
                    }
                }
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
            }
        }
    }

    get selectedTrip() {
        const trip = this.value;
        return trip instanceof Trip ? trip : NULL_TRIP;
    }

    set selectedTrip(trip) {
        this.$emit('trip-selected', trip instanceof Trip ? trip : null);
    }


    get pendingTrips() {
        const trips = _.chain(this.trips)
            .sortBy((t) => t.startDate)
            .map((t) => {
                return {
                    text: sprintf('%s (%s)', t.workshop, _.isNull(t.startDate) ? 'Unknown' :
                        formatters.date(t.startDate, 'M/d/yyyy')),
                    value: t
                };
            })
            .value();
        return trips;
    }

    @Watch('value')
    clearSelectedTraveler(value) {
        if (_.isNull(value)) {
            this.selectedTraveler = null;
            this.trips = [];
        }
    }
}

</script>
<style scoped>

</style>