<template>
    <b-table-lite small striped borderless outlined head-variant="dark" stacked="md"
                  :table-variant="tableVariant"
                  :fields="fields" :items="tableData">
        <template #cell(originalCost)="row">{{ row.item.originalCost | currency }}</template>
    </b-table-lite>
</template>
<script>

import {Vue, Component, Prop} from 'vue-property-decorator';
import _ from 'underscore';
import {Trip} from '@/model/travel';

@Component({
    filters: {
        currency: c => {
            const v = parseFloat(c) || null;
            return null === v ? '' : `$${v.toFixed(2)}`;
        }
    }
})
export default class BookedAirfare extends Vue {

    @Prop({type: Trip}) trip;
    @Prop({type: String, default: 'success'}) tableVariant;

    get selectedTrip() {
        return this.trip || {};
    }

    get airfare() {
        return this.selectedTrip.airfare || [];
    }

    get fields() {
        return [{
            key: 'ticketStatus',
            label: 'Ticket Status'
        },{
            key: 'airline',
            label: 'Airline'
        },{
            key: 'ticketNumber',
            label: 'Ticket Number'
        }, {
            key: 'confirmationCode',
            label: 'Confirmation Code'
        }, {
            key: 'originalCost',
            label: 'Ticket Cost'
        }];
    }

    get tableData() {
        return _.chain(this.airfare)
            .sortBy(af => af.issueDate)
            .value();
    }
}

</script>