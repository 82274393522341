<template>
    <b-tab :title="title" :disabled="!navigable" lazy>
        <b-row>
            <b-col cols="md-6">
                <b-row>
                    <b-col>
                        <b-card no-body header-bg-variant="success" header-text-variant="white">
                            <template v-slot:header><strong>Workshop</strong></template>
                            <b-card-body>{{workshop}}</b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-card no-body :header-bg-variant="dormException ? 'warning' : 'success'" header-text-variant="white">
                            <template v-slot:header>
                                <div class="d-flex w-100 justify-content-between">
                                    <strong>Dorm Room(s)</strong>
                                    <small v-if="dormException">Exception to CITF Travel Policy</small>
                                </div>
                            </template>
                            <b-table-lite small stacked="md" striped borderless head-variant="dark"
                                          :fields="dormTableFields"
                                          :items="dormStays"
                                          v-if="hasDormRooms">
                                <template v-slot:cell(checkIn)="row">{{row.item.checkIn | formatDate}}</template>
                                <template v-slot:cell(checkOut)="row">{{row.item.checkOut | formatDate}}</template>
                            </b-table-lite>
                            <b-card-body v-else>
                                <strong>Not Selected</strong>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-card no-body :header-bg-variant="airfareException ? 'warning' : 'success'" header-text-variant="white">
                            <template v-slot:header>
                                <div class="d-flex w-100 justify-content-between">
                                    <strong>Flight Request(s)</strong>
                                    <small v-if="airfareException">Exception to CITF Travel Policy</small>
                                </div>
                            </template>
                            <b-table-lite small stacked="md" striped borderless head-variant="dark"
                                          :fields="airfareTableFields"
                                          :items="flightRequests"
                                          v-if="hasFlightRequests">
                                <template v-slot:cell(direction)="row">{{ row.item | flightDirection }}</template>
                                <template v-slot:cell(date)="row">{{row.item.dateOfFlight | formatDate}}</template>
                                <template v-slot:cell(departs)="row">
                                    {{row.item.departCity}},
                                    {{getState(row.item.departStateId).stateAbbreviation}}
                                    ({{getAirport(row.item.departAirportId).iata}})
                                </template>
                                <template v-slot:cell(arrives)="row">
                                    {{row.item.arriveCity}},
                                    {{getState(row.item.arriveStateId).stateAbbreviation}}
                                    ({{getAirport(row.item.arriveAirportId).iata}})
                                </template>
                                <template v-slot:row-details="row">
                                    <b-list-group>
                                        <b-list-group-item variant="light">
                                            <b-row>
                                                <b-col cols="md-6">
                                                    <div><strong class="mb-1">Preferred Flight Time:</strong></div>
                                                    <p class="mb-1">{{row.item.preferredFlightTime}}</p>
                                                </b-col>
                                                <b-col cols="md-6">
                                                    <div><strong class="mb-1">Additional Requests:</strong></div>
                                                    <p class="mb-1">{{row.item.additionalRequests}}</p>
                                                </b-col>
                                            </b-row>
                                        </b-list-group-item>
                                    </b-list-group>
                                </template>
                            </b-table-lite>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="md-6">
                <b-row>
                    <b-col>
                        <b-card no-body header-bg-variant="success" header-text-variant="white">
                            <template v-slot:header>
                                <div class="d-flex w-100 justify-content-between">
                                    <strong>Traveler Information</strong>
                                    <small>Confirmed!</small>
                                </div>
                            </template>
                            <b-card-body>
                                <b-row>
                                    <b-col cols="md-6">
                                        <b-form-group label="Name:"><strong>{{travelerName}}</strong></b-form-group>
                                    </b-col>
                                    <b-col cols="md-6">
                                        <b-form-group label="Direct Phone:"><strong>{{phone}}</strong></b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group>
                                            <template v-slot:label>{{traveler.showContractorInstead ? 'Contractor' : 'Organization'}}:</template>
                                            <strong>{{traveler.organization}}</strong>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="md-6">
                                        <b-form-group label="Gender:"><strong>{{gender}}</strong></b-form-group>
                                    </b-col>
                                    <b-col cols="md-6">
                                        <b-form-group label="Date of Birth:"><strong>{{dateOfBirth}}</strong></b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="md-6">
                                        <b-form-group label="Itinerary Email Address(es):">
                                            <b-form-tags v-model="emails" disabled placeholder=""/>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="md-6">
                                        <b-form-group label="Dietary Needs:">
                                            <strong>{{dietary}}</strong>
                                        </b-form-group>
                                        <b-form-group label="ADA Needs:">
                                            <strong>{{ada}}</strong>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row v-if="showBookedTravel">
                    <b-col>
                        <b-card no-body header-bg-variant="success" header-text-variant="white">
                            <template v-slot:header>
                                <strong>Booked Airfare</strong>
                            </template>
                            <booked-airfare :trip="trip" able-variant="success"/>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-tab>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import {Trip, NULL_TRAVELER, FlightRequest, NULL_AIRPORT} from '@/model/travel';
import BookedAirfare from '@/views/private/travel/BookedAirfare';
import {date} from '@/util/formatters';
import {sprintf} from 'sprintf-js';
import _ from 'underscore';
import {NULL_STATE} from '@/model/common';

@Component({
    components: {
        BookedAirfare
    },
    filters: {
        formatDate: (d) => date(d, 'M/d/yyyy'),
        flightDirection: (fr) => sprintf('%s the ITC', 'to' === fr.direction ? 'To' : 'From')
    }
})
export default class Confirmation extends Vue {
    @Prop({type: String, default: 'Confirm'}) title;
    @Prop({type: Boolean, default: true}) navigable;
    @Prop({type: Trip}) trip;
    @Prop({type: Boolean}) dormException;
    @Prop({type: Boolean}) airfareException;

    get workshop() {
        return !!this.trip ? sprintf('%s (%s - %s)',
            this.trip.workshop,
            date(this.trip.startDate, 'M/d/yyyy'),
            date(this.trip.endDate, 'M/d/yyyy')
        ) : '';
    }

    get traveler() {
        return this.trip instanceof Trip ? this.trip.traveler : NULL_TRAVELER;
    }

    get travelerName() {
        return this.traveler.workspace.fullName;
    }

    get organization() {
        return this.traveler.organization;
    }

    get phone() {
        return this.traveler.workspace.phone.formatted;
    }

    get emails() {
        return (this.traveler.workspace.email || '').split(';');
    }

    get gender() {
        return 'M' === this.traveler.workspace.gender ? 'Male' : 'Female';
    }

    get dietary() {
        return this.traveler.workspace.dietary;
    }

    get ada() {
        return this.traveler.workspace.ada;
    }

    get dateOfBirth() {
        return _.isDate(this.traveler.workspace.dateOfBirth) ? date(this.traveler.workspace.dateOfBirth, 'M/d/yyyy') : '';
    }

    get hasDormRooms() {
        return !!this.trip && !_.isEmpty(this.trip.dormStays);
    }

    get hasFlightRequests() {
        return !!this.trip && !_.isEmpty(this.trip.flightRequests);
    }

    get dormTableFields() {
        return [{
            key: 'checkIn',
            label: 'Check-In'
        }, {
            key: 'checkOut',
            label: 'Check-Out'
        }];
    }

    get dormStays() {
        return this.trip instanceof Trip ? this.trip.dormStays : [];
    }

    get airfareTableFields() {
        return [{
            key: 'direction',
            label: 'Direction'
        }, {
            key: 'date',
            label: 'Date of Flight'
        }, {
            key: 'departs',
            label: 'Departs'
        }, {
            key: 'arrives',
            label: 'Arrives'
        }];
    }

    get flightRequests() {
        return _.chain(['to', 'from'])
            .map((direction) => this.getFlight(direction))
            .map((fr) => {
                if (fr instanceof FlightRequest) {
                    fr._showDetails = true;
                }

                return fr;
            })
            .filter((fr) => fr instanceof FlightRequest)
            .sortBy((fr) => fr.dateOfFlight)
            .value();
    }

    get activeUser() {
        return this.$store.getters['userSession/getUser'];
    }

    get showBookedTravel() {
        return this.activeUser.isAnAdministrator() && (this.trip || {}).hasBookedAirfare
    }

    getFlight(direction) {
        return !!this.trip ? this.trip.flightRequests[direction] : null;
    }

    getAirport(airportId) {
        return this.$store.getters['travel/getAirportById'](airportId) || NULL_AIRPORT;
    }

    getState(stateId) {
        return this.$store.getters['common/getStateById'](stateId) || NULL_STATE;
    }
}
</script>
<style scoped>

</style>