<template>
    <b-tab title="Traveler Information" :disabled="!navigable" lazy>
        <b-row>
            <b-col>
                <b-table-lite striped stacked borderless outlined head-variant="dark" :fields="fields" :items="tableData">
                    <template v-slot:cell(name)>{{selectedTraveler.fullName}}</template>
                    <template v-slot:cell(email)>
                        <template v-if="disabled">{{emails.join(', ')}}</template>
                        <b-form-group :state="feedbackState('email')"
                                      invalid-feedback="At least one Email Address is Required."
                                      v-else>
                            <b-tags size="sm"
                                    v-model="emails"
                                    placeholder="Add Email Address"
                                    :tag-validator="emailValidator"
                                    :invalid-tag-text="invalidEmailFeedback"
                                    input-type="email"
                                    duplicate-tag-text="Duplicate Email Address"
                                    add-button-text="Add"
                                    add-button-variant="primary"
                                    tag-pills
                                    tag-variant="success"
                                    :disabled="disabled"
                                    :state="feedbackState('email')"/>
                            <template v-slot:description>
                                Email Address(es) where the itinerary will be mailed.
                                Press &lt;Enter&gt; after entering a valid email address,
                                or press the <b-badge variant="primary">Add</b-badge> button.
                            </template>
                        </b-form-group>
                    </template>
                    <template v-slot:cell(dietary)>
                        <template v-if="disabled">{{selectedTraveler.dietary}}</template>
                        <b-input size="sm" v-model="selectedTraveler.workspace.dietary" :disabled="disabled" v-else/>
                    </template>
                    <template v-slot:cell(ada)>
                        <template v-if="disabled">{{selectedTraveler.ada}}</template>
                        <b-input size="sm" v-model="selectedTraveler.workspace.ada" :disabled="disabled" v-else/>
                    </template>
                    <template v-slot:cell(phone)>
                        <template v-if="disabled">{{selectedTraveler.workspace.phone.formatted}}</template>
                        <b-form-group :state="feedbackState('phone')" invalid-feedback="Phone Number is Required." v-else>
                            <phone-number input-class="form-control form-control-sm"
                                          v-model="selectedTraveler.workspace.phone.formatted"
                                          :disabled="disabled"
                                          show-status/>
                            <template v-slot:description>Best number to directly reach traveler.</template>
                        </b-form-group>
                    </template>
                </b-table-lite>
            </b-col>
        </b-row>
    </b-tab>
</template>
<script>

import {Vue, Prop, Component} from 'vue-property-decorator';
import {Traveler, NULL_TRAVELER} from '@/model/travel';
import PhoneNumber from '@/components/shared/PhoneNumber.vue';
import {validEmailAddress} from '@/util/validation';
import _ from 'underscore';
import {sprintf} from 'sprintf-js';

@Component({
    components: {
        PhoneNumber
    }
})
export default class TravelerInformation extends Vue {

    @Prop({type: Boolean, default: false}) navigable;
    @Prop({type: Traveler}) traveler;
    @Prop({type: Boolean, default: false}) disabled;

    isDuplicate = false;

    get selectedTraveler() {
        return this.traveler instanceof Traveler ? this.traveler : NULL_TRAVELER;
    }

    get emails() {
        return _.map((this.selectedTraveler.workspace.email || '').split(';'), (e) => e.toLowerCase());
    }

    set emails(emails) {
        this.selectedTraveler.workspace.email = _.map(emails, (e) => e.toLowerCase()).join(';');
    }

    get fields() {
        return [{
            key: 'name',
            label: 'Name:'
        }, {
            key: 'ubcId',
            label: 'UBC ID:'
        }, {
            key: 'organization',
            label: this.selectedTraveler.showContractorInstead ? 'Contractor:' : 'Organization:'
        }, {
            key: 'phone',
            label: 'Direct Phone:'
        }, {
            key: 'email',
            label: 'Email(s):'
        }, {
            key: 'dietary',
            label: 'Dietary Needs:'
        }, {
            key: 'ada',
            label: 'ADA Needs:'
        }];
    }

    get tableData() {
        return [this.selectedTraveler];
    }

    get invalidEmailFeedback() {
        return sprintf('%s Email Address', this.isDuplicate ? 'Duplicate' : 'Invalid');
    }

    emailValidator(email) {

        if (!this.isValidEmailAddress(email)) {
            this.isDuplicate = false;
            return false;
        }

        if (this.isDuplicateEmailAddress(email)) {
            this.isDuplicate = true;
            return false;
        }

        return true;
    }

    isValidEmailAddress(email) {
        return validEmailAddress(email);
    }

    isDuplicateEmailAddress(email) {
        return _.any(this.emails, (e) => e.toLowerCase() === email.toLowerCase());
    }

    feedbackState(field) {
        switch (field) {
            case 'phone':
                return this.selectedTraveler.workspace.phone.isValid;

            case 'email':
                const emails = (this.selectedTraveler.workspace.email || '').split(';');
                return !_.isEmpty(emails) && _.all(emails, validEmailAddress);
        }
    }

    isValid() {
        return this.feedbackState('phone') && this.feedbackState('email');
    }
}

</script>
<style scoped>

</style>