<template>
    <b-card no-body>
        <b-row>
            <b-col>
                <b-tabs small v-model="tabIndex" card @input="onTabChanged">
                    <travel-registrations ref="travelRegistrationTab"
                                          v-if="showRegistrationTab"
                                          :navigable="true"
                                          @registered="registered"/>
                    <select-traveler ref="selectTravelerTab"
                                     @trip-selected="tripSelected"
                                     @traveler-changed="reset(false, false)"
                                     :attendee-id="attendee"
                                     :registration-id="registration"
                                     v-model="selectedTrip"/>
                    <traveler-information ref="travelerInformationTab"
                                          :navigable="tabVisible('traveler-information')"
                                          :traveler="(selectedTrip || {}).traveler"/>
                    <travel-policy @question-answered="questionAnswered"
                                   :navigable="tabVisible('travel-policy')"
                                   :policyAgreedTo="policyAgreedTo"
                                   :dormRoom="needsDormRoom"
                                   :airfare="needsAirfare"/>
                    <select-dorm-room ref="selectDormRoom"
                                      :navigable="tabVisible('dorm-room')"
                                      :trip="selectedTrip"
                                      @dorm-stays-selected="dormStaysSelected"/>
                    <select-airfare ref="selectAirfare"
                                    :navigable="tabVisible('airfare')"
                                    :trip="selectedTrip"
                                    :dorm-stays="dormStays"
                                    @flight-requests-selected="flightRequestsSelected"/>
                    <confirmation :navigable="tabVisible('confirmation')"
                                  :dorm-exception="dormException"
                                  :airfare-exception="airfareException"
                                  :trip="selectedTrip"/>
                </b-tabs>
            </b-col>
        </b-row>
        <b-row class="ml-1 mr-1 mb-3">
            <b-col cols="6">
                <b-button-group size="sm">
                    <b-button variant="warning" v-if="buttonVisible('start-over')" @click="reset(true,true)">Start Over</b-button>
                </b-button-group>
            </b-col>
            <b-col class="clearfix">
                <b-button-group
                        size="sm"
                        class="float-right">
                    <b-button
                            variant="danger"
                            v-if="buttonVisible('decline-travel')"
                            @click="declineTravel">Decline Room & Airfare</b-button>
                    <b-button
                            variant="primary"
                            v-if="buttonVisible('previous')"
                            @click="previous">Previous</b-button>
                    <b-button
                            variant="success"
                            v-if="buttonVisible('next')"
                            @click="next">Next</b-button>
                    <b-button
                            variant="success"
                            v-if="buttonVisible('accept')"
                            @click="acceptTravel">
                        Accept {{ needsDormRoom && needsAirfare ? 'Room & Airfare' : (needsDormRoom ? 'Room' : 'Airfare') }}
                    </b-button>
                </b-button-group>
            </b-col>
        </b-row>
    </b-card>
</template>
<script>

    import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import store from '@/store/store';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import travelDao from '@/dao/travel_dao';
import TravelRegistrations from '@/views/private/travel/TravelRegistrations.vue';
import SelectTraveler from '@/views/private/travel/SelectTraveler.vue';
import TravelerInformation from '@/views/private/travel/TravelerInformation.vue';
import TravelPolicy from '@/views/private/travel/TravelPolicy.vue';
import SelectDormRoom from '@/views/private/travel/SelectDormRoom.vue';
import SelectAirfare from '@/views/private/travel/SelectAirfare.vue';
import Confirmation from '@/views/private/travel/Confirmation.vue';
import {Trip} from '@/model/travel';
import _ from 'underscore';
import {isAfter} from 'date-fns';
    import {NULL_TRIP} from "../../../model/travel";
    import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

@Component({
    components: {
        TravelRegistrations,
        SelectTraveler,
        TravelerInformation,
        TravelPolicy,
        SelectDormRoom,
        SelectAirfare,
        Confirmation
    },

    asyncComputed: {
        async travelPolicy() {
            try {
                return await travelDao.getTravelPolicy();
            }
            catch (error) {
                this.$bvToast.toast(error.message, errorToastOptions);
                return '';
            }
        }
    }
})
export default class Travel extends Vue {
    @Prop({type: [Number, String], default: 0}) attendeeId;
    @Prop({type: [Number, String], default: 0}) registrationId;

    tabIndex = 0;
    attendee = 0;
    registration = 0;
    selectedTrip = null;
    policyAgreedTo = null;
    needsDormRoom = null;
    needsAirfare = null;

    mounted() {
        this.attendee = this.attendeeId;
        // console.log('Attendee ID', this.attendee);
        this.registration = this.registrationId;
        // console.log('Registration ID', this.registration);
        //Jump to SelectTraveler with a prop/pass-in
        if (this.showRegistrationTab && this.attendee !== 0) {
            this.tabIndex = 1;
        }
    }

    async registered(details) {
        try {
            await this.$store.dispatch('travel/loadPendingTravelers');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
        if (!!details) {
            this.attendee = !!details.attendeeId ? details.attendeeId : 0;
            this.registration = !!details.registrationId ? details.registrationId : 0;
        }
        if (!_.isUndefined(this.$refs.selectTravelerTab)) {
            //Registration action will affect available travel
            await this.$refs.selectTravelerTab.refreshTrips();
        }
        this.next();
    }

    get activeUser() {
        return this.$store.getters['userSession/getUser'];
    }

    get showRegistrationTab() {
        //Don't show for trainees - no access
        return !!this.activeUser && (this.activeUser.isAnInstructor() || this.activeUser.isAMentor());
    }

    get dormException() {
        return this.needsDormRoom && !!this.$refs.selectDormRoom && this.$refs.selectDormRoom.hasErrors() && this.activeUser.isAnAdministrator();
    }

    get airfareException() {
        return this.needsAirfare && !!this.$refs.selectAirfare && !this.$refs.selectAirfare.isValidSelection && this.activeUser.isAnAdministrator();
    }

    get dormStays() {
        return this.isTripSelected ? this.selectedTrip.dormStays : [];
    }

    set dormStays(dormStays) {
        if (this.isTripSelected) {
            this.selectedTrip.dormStays = dormStays;
        }
    }

    get flightRequests() {
        return this.isTripSelected ? this.selectedTrip.flightRequests : {};
    }

    set flightRequests(flightRequests) {
        if (this.isTripSelected) {
            this.selectedTrip.flightRequests = flightRequests;
        }
    }

    get user() {
        return this.$store.getters['userSession/getUser'];
    }

    get isTripSelected() {
        return this.selectedTrip instanceof Trip;
    }

    tripSelected(trip) {
        this.selectedTrip = trip;
        this.tabIndex++;
    }

    questionAnswered(question, answer) {
        switch (question) {
            case 'policyAgreedTo':
            case 'needsDormRoom':
            case 'needsAirfare':
                this[question] = true === answer;
                break;

            default:
                break;
        }
    }

    dormStaysSelected(dormStays) {
        this.dormStays = dormStays;
    }

    flightRequestsSelected(flightRequests) {
        this.flightRequests = flightRequests;
    }

    reset(includeTrip, includeAttendeeRegistration) {
        if (includeTrip) {
            this.selectedTrip = null;
        }
        this.tabIndex = this.showRegistrationTab ? 1 : 0;
        if (includeAttendeeRegistration) {
            this.attendee = 0;
            this.registration = 0;
        }
        this.policyAgreedTo = null;
        this.needsDormRoom = null;
        this.needsAirfare = null;
        this.$refs.selectDormRoom.reset();
        this.$refs.selectAirfare.reset();
    }

    next() {
        this.tabIndex++;
        this.onTabChanged();
    }

    async declineTravel() {
        const check = await this.$bvModal.msgBoxConfirm(
            'Are you sure you want to decline travel?', {
                title: 'Confirm',
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                headerBgVariant: 'dark',
                headerTextVariant: 'white'
            });
        if (!check) {
            return;
        }
        try {
            await this.$store.dispatch('registration/declinedTravel', {
                registrationId: this.selectedTrip.registrationId,
                declinedTravel: true
            }, {root: true});
            this.selectedTrip = null;
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    previous() {
        this.tabIndex--;
        this.onTabChanged();
    }

    tabVisible(tab) {
        switch (tab) {
            case 'select-traveler':
                return true;

            case 'traveler-information':
                return this.isTripSelected;

            case 'travel-policy':
                return this.isTripSelected && this.$refs.travelerInformationTab.isValid();

            case 'dorm-room':
                return this.isTripSelected && this.policyAgreedTo && this.needsDormRoom && null !== this.needsAirfare;

            case 'airfare':
                return this.isTripSelected && // Trip must be selected
                    this.policyAgreedTo &&  // Travel policy
                    null !== this.needsDormRoom && // The needs dorm room question must be answered
                    this.needsAirfare && // The needs airfare question has been answered 'Yes'
                    (!this.needsDormRoom || !_.isEmpty(this.dormStays)); // If needs dorm room was answered 'Yes' then at least one
                                                                           // dorm stay must have been selected

            case 'confirmation':
                return this.isTripSelected && // Trip must be selected
                    this.policyAgreedTo &&  // Travel policy
                    null !== this.needsDormRoom && // The needs dorm room question must be answered
                    null !== this.needsAirfare && // The needs airfare question must be answered
                    (!this.needsDormRoom || !_.isEmpty(this.dormStays)) && // If needs dorm room was answered 'Yes' then at least one
                                                                           // dorm stay must have been selected
                    (!this.needsAirfare || !_.isEmpty(this.flightRequests)) && // If needs airfare was answered 'Yes' then at least one
                                                                               // flight has been selected
                    (!this.needsAirfare || this.selectedTrip.traveler.workspace.confirmed); // If needs airfare was answered 'Yes' then
                                                                                            // The travelers's name/dob/gender must be confirmed
            default:
                return false;
        }
    }

    get tabId() {
        if (this.showRegistrationTab) {
            switch (this.tabIndex) {
                case 0: return 'registrations';
                case 1: return 'select-traveler';
                case 2: return 'traveler-information';
                case 3: return 'travel-policy';
                case 4: return 'dorm-room';
                case 5: return 'airfare';
                case 6: return 'confirmation';
            }
        }
        else {
            switch (this.tabIndex) {
                case 0: return 'select-traveler';
                case 1: return 'traveler-information';
                case 2: return 'travel-policy';
                case 3: return 'dorm-room';
                case 4: return 'airfare';
                case 5: return 'confirmation';
            }
        }
    }

    buttonVisible(button) {
        switch (button) {
            case 'start-over':
                return this.tabIndex > 1 && !!this.selectedTrip;

            case 'previous':
                return this.tabIndex > 1;

            case 'next':
                switch(this.tabId) {
                    case 'select-traveler':
                        return this.isTripSelected;

                    case 'traveler-information':
                        return this.isTripSelected && this.$refs.travelerInformationTab.isValid();

                    case 'travel-policy':
                        // The trip must be selected
                        return this.isTripSelected &&
                            // The policy must be agreed to
                            this.policyAgreedTo &&
                            // Both questions about dorm room and airfare must be answered
                            null !== this.needsDormRoom && null !== this.needsAirfare &&
                            // At least one of dorm room or airfare was answered 'Yes'
                            (this.needsDormRoom || this.needsAirfare);
                    case 'dorm-room':
                        // The trip must be selected
                        return this.isTripSelected &&
                            // The policy must be agreed to
                            this.policyAgreedTo &&
                            // If dorm rooms are being requested, make sure that dorm rooms have been selected
                            null !== this.needsDormRoom && (!this.needsDormRoom || !_.isEmpty(this.dormStays));

                    case 'airfare':
                        // The trip must be selected
                        return this.isTripSelected &&
                            // The policy must be agreed to
                            this.policyAgreedTo &&
                            // If dorm rooms are being requested, make sure the dorm rooms have been selected
                            null !== this.needsDormRoom && (!this.needsDormRoom || !_.isEmpty(this.dormStays)) &&
                            // If airfare is requested, make sure flights have been selected
                            null !== this.needsAirfare && (!this.needsAirfare || !_.isEmpty(this.flightRequests)) &&
                            // The traveler's name must be confirmed
                            this.selectedTrip.traveler.workspace.confirmed &&
                            // The traveler's DOB isn't in the future
                            _.isDate(this.selectedTrip.traveler.workspace.dateOfBirth) &&
                            isAfter(new Date(), this.selectedTrip.traveler.workspace.dateOfBirth);

                    case 'confirmation':
                        return false;

                    default:
                        return false;
                }

            case 'decline-travel':
                return this.tabId === 'select-traveler' && this.isTripSelected;

            case 'accept':
                // The trip must be selected
                return this.isTripSelected &&
                    // The policy must be agreed to
                    this.policyAgreedTo &&
                    // At least one of needs dorm room or needs airfare has been selected
                    (this.needsDormRoom || this.needsAirfare) &&
                    // If dorm rooms are being requested, make sure the dorm rooms have been selected
                    (!this.needsDormRoom || !_.isEmpty(this.dormStays)) &&
                    // If airfare is requested, make sure flights have been selected
                    (!this.needsAirfare || !_.isEmpty(this.flightRequests)) &&
                    (!this.needsAirfare ||
                        (this.selectedTrip.traveler.workspace.confirmed &&
                            _.isDate(this.selectedTrip.traveler.workspace.dateOfBirth) &&
                            isAfter(new Date(), this.selectedTrip.traveler.workspace.dateOfBirth))) &&
                    // And we need to be on the last tab, so the 'next' button cannot be visible
                    !this.buttonVisible('next');

            default:
                return false;
        }
    }

    get onSelectedTravelerTab() {
        const selectTravelerTab = this.showRegistrationTab ? 1 : 0;
        return this.tabIndex === selectTravelerTab;
    }

    onTabChanged() {
        //TODO - deprecate?
        // if (this.onSelectedTravelerTab && this.isTripSelected) {
            // this.$refs.selectTravelerTab.toggleSelectedTraveler(this.selectedTrip.traveler);
            // this.$refs.selectTravelerTab.toggleSelectedTrip(this.selectedTrip);
        // }
    }

    async acceptTravel() {

        const trip = this.selectedTrip.cloneTemplate();
        trip.traveler = this.selectedTrip.traveler.workspace.cloneTemplate();

        // Since dormStays is a computed property, upon which the code making the exceptional state
        // determination hinges, we cannot cache the exceptional state at the time of DormStay
        // object creation (hasErrors() calls into dormStays() which then calls into hasErrors() - too much recursion!)
        // we must map the exceptional state to the payload here
        trip.dormStays = _.map(trip.dormStays, (ds) => {
            ds.additionalRequests = this.$refs.selectDormRoom.additionalRequests;
            ds.exception = this.dormException;
            return ds;
        });
        try {
            await travelDao.saveTrip(trip);
            await this.$store.dispatch('travel/loadPendingTravelers');
            this.reset(true, true);
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            this.reset(true, false);
        }
    }

    async beforeRouteEnter(to, from, next) {

        const activeUser = store.getters['userSession/getUser'];

        if (activeUser.isATrainee()) {
            return this.$router.push('/admin');
        }

        store.commit('setBreadcrumbs', [
            Breadcrumb.create('Administration', {name: 'adminMain'}, false),
            Breadcrumb.create('Travel', {name: 'travel'}, false)
        ]);
        _.each([
            'common/loadStates',
            'travel/loadPolicy'
        ], (action) => {
            store.dispatch(action)
                .catch(error => {
                    const vm = new Vue();
                    vm.$bvToast.toast(error.message, errorToastOptions);
                });
        });
        next();
    }
}

</script>
<style>
    a.test-event {
        background-color: #F00;
        border: 1px solid #000;
    }
</style>
